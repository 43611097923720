import "./Greeting.scss"
import {PiDribbbleLogoFill, PiGithubLogoFill} from "react-icons/pi";
import {SiMinutemailer} from "react-icons/si";
import {MdEmail} from "react-icons/md";
import {TfiLinkedin} from "react-icons/tfi";
import {FaDeviantart} from "react-icons/fa";

function Greeting() {
    return (
        <div className="hero">
            <div className="hero__inside">

                <div className="hero__wrapper">

                    <div className="d-flex justify-content-center">
                        <h2 className="ml11">
							<span className="text-wrapper">
								<span className="line line1"></span>
								<span className="letters">STEFANOS KARAKASIS</span>
							</span>
                        </h2>
                    </div>

                    <h4 className="hero__title-header">Where Bugs Turn into Features</h4>

                    <h1 className="hero__subtitle mb-3 d-none">
                        <strong>Full-Stack Developer.</strong>
                        <span className="d-none">
                            I work on the field of Enterprise Web Development with 4+ work experience, my passion is to create software that's appealing to the users with edge-cutting technologies.
                        </span>
                    </h1>

                    {/*<a className="button" href="#portfolio">quick tour</a>*/}
                </div>

                <div className="d-flex position-relative">
                    <img className="hero__image" src="/images/grey.jpg" alt="photo of Stefanos Karakasis"/>
                    <div className="hero__circle"></div>
                </div>

                <div className="hero__social">
                    <a target="_blank" href="https://linkedin.com/in/steve-karakasis-148824147">
                        <TfiLinkedin size={22} />
                    </a>

                    <a target="_blank" href="https://github.com/stefos96/">
                        <PiGithubLogoFill size={22} />
                    </a>

                    <a target="_blank" href="https://dribbble.com/stefos96">
                        <PiDribbbleLogoFill size={22} />
                    </a>

                    <a target="_blank" href="https://www.deviantart.com/stefos96">
                        <FaDeviantart size={22} />
                    </a>

                    <a target="_blank" href="mailto:stefanoskarakasis@outlook.com">
                        <MdEmail size={22} />
                    </a>

                </div>


            </div>
        </div>
    );
}

export default Greeting;
