import "./Header.scss"

function Header() {
    return (
        <header className="header">
            <div className="header__wrapper">
                <a className="logo" href="."><img className="logo-image" src="/images/icons/sk_logo_grey.svg"
                                                  alt="logo"/></a>

                <div data-cmp="hamburger" className="hamburger">
                    <img className="hamburger__icon" src="/images/icons/hamburger_icon_grey.png" alt="hamburger-icon"/>
                </div>

                <div data-cmp="menu" className="menu" id="menu">
                    <a className="menu__item" href="#about">ABOUT</a>
                    <a className="menu__item" href="#work">WORK</a>
                    {/*<a className="menu__item" href="#experience">EXPERIENCE</a>*/}
                    {/*<a className="menu__item" href="#contact">CONTACT</a>*/}
                    {/*<a className="menu__item d-md-none" href="#contact-me">CONTACT ME</a>*/}

                    <img data-cmp="menu-close" className="menu__close-icon" src="/images/icons/close_white.svg" alt="menu close"/>
                </div>
            </div>

            <a className="button menu__item d-md-block d-none" href="#contact">CONTACT ME</a>
        </header>
);
}

export default Header;
