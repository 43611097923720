import {motion, useScroll, useTransform} from "framer-motion"
import {useRef} from "react";

function AnimateDiv({children}) {

    const scrollRef = useRef(null)
    const {scrollYProgress} = useScroll({target: scrollRef, offset: ["start end", "start center"]});

    const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.2, 1])
    const opacityProgress = useTransform(scrollYProgress, [0, 1], [0, 1])

    return (
        <motion.div
            style={{
                opacity: opacityProgress,
                scale: scaleProgress,
            }}
            ref={scrollRef}>
            {children}
        </motion.div>
    );
}

export default AnimateDiv;
