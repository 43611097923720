import $ from "jquery";

// used elastic
$(document).ready(function () {
    const selectors = {
        sendEmailButton: '[data-cmp="send-email"]',
        senderName: '[data-cmp="sender-name"]',
        senderEmail: '[data-cmp="sender-email"]',
        senderText: '[data-cmp="sender-text"]',
    }

    $(selectors.sendEmailButton).on('click', () => {
        let name = $(selectors.senderName).val().trim();
        let email = $(selectors.senderEmail).val().trim();
        let text = $(selectors.senderText).val().trim();

        if (name === '' || email === '' || text === '') {
            // TODO: MAKE THIS
            // if (name === '') {
            //     $(selectors.senderName).addClass('error');

            // }

            // if (name === '') {

            // }

            // if (name === '') {

            // }
        } else {
            let body = `name: ${name}<br>email: ${email}<br>text: ${text}<br>`;

            window.Email.send({
                SecureToken: 'fd1152c9-6762-4be0-91f5-0c79cd5ed69b',
                To: 'stefos96@hotmail.com',
                From: "stefos12k@gmail.com",
                Subject: "Website - Stefanos Karakasis",
                Body: body
            }).then(
                $(selectors.sendEmailButton).addClass('sent').text('SENT!')
            );
        }
    })

});
