import $ from "jquery";

$(document).ready(function () {
    let sectionIds = $('.header .menu__item');

    scrollAction();
    $(window).on('scroll', scrollAction);

    function scrollAction() {
        let scrollPosition = $(document).scrollTop();

        if (scrollPosition >= 50) {
            $('.header').addClass('moved');
        } else {
            $('.header').removeClass('moved');
        }

        sectionIds.each(function () {
            let container = $(this).attr('href');
            let containerOffset = $(container).offset().top;
            let containerHeight = $(container).outerHeight();
            let containerBottom = containerOffset + containerHeight;
            let halfWindowHeight = $(window).height() / 2;

            if (scrollPosition < containerBottom - halfWindowHeight && scrollPosition >= containerOffset - halfWindowHeight) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
    }

});