import $ from "jquery";

$(document).ready(function () {
    const selectors = {
        triggerHover: '[data-cmp="trigger-hover"]',
        hoverComponent: '[data-hover-cmp="true"]',
        cardDetails: '[data-cmp="card-details"]',
        hoverRow: '[data-hover="row"]',
    }

    $(selectors.cardDetails).on('click', function () {
        $(this).toggleClass('flipped');
    });

    $(selectors.triggerHover).each(function (index) {
        $(this).on('click', function () {
            let trigger = $(this).data('trigger');
            $(this).toggleClass('active');
            $(this).closest(selectors.hoverRow).toggleClass('active');
            $(`[data-cmp="${trigger}"]`).toggleClass('active');
        });
    });

    $('#portfolio').on('mousemove', hoverEffectOnCards);

    async function hoverEffectOnCards(evt) {
        let x = evt.pageX - $(this).offset().left;
        let y = evt.pageY - $(this).offset().top;

        let widthMinusX = $(this).width() / 2 - parseInt(x);
        let heightMinusY = $(this).height() / 2 - parseInt(y);

        let finalX = widthMinusX * .03;
        let finalY = heightMinusY * .03;

        $(selectors.hoverComponent).children().each(function (index) {
            let perspective = 35 * (index + 1);

            let myStyle = `transform: perspective(${perspective}em) rotateX(${finalY}deg) rotateY(${finalX}deg) rotateZ(0);`;

            $(this).css('cssText', myStyle);
        });

    }
});