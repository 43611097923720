import "./Work.scss"
import AnimateDiv from "../AnimateDiv/AnimateDiv";

function Work() {
    return (
        <div className="work">
            <AnimateDiv>
                <div className="d-flex flex-column align-items-center">
                    <div className="text-center mb-5">
                        <h4 className="hero__title mb-5">EXPERIENCE.</h4>
                        <div className="hero__subtitle mw-100">
                            My work experience:
                        </div>
                    </div>

                    <div className="work__wrapper mb-5">
                        <div className="work__row active">
                            <div className="work__text">2020-PRESENT DELOITTE DIGITAL</div>
                            <div className="work__text">Consultant 3</div>
                        </div>

                        <div className="work__row">
                            <div className="work__text">2019-2020 ARTABOUT ECOMMENCE</div>
                            <div className="work__text">Junior Web Developer</div>
                        </div>
                    </div>

                    <div className="text-center mb-5">
                        <div className="hero__subtitle mw-100">
                            My educational path:
                        </div>
                    </div>

                    <div className="work__wrapper mb-5">
                        <div className="work__row">
                            <div className="work__text">Bachelor</div>
                            <div className="work__text">Software Engineer in International Hellenic University
                            </div>
                        </div>

                        <div className="work__row">
                            <div className="work__text">Adobe Experience Manager</div>
                            <div className="work__text">Business Practitioner</div>
                        </div>

                        <div className="work__row">
                            <div className="work__text">Adobe Experience Manager</div>
                            <div className="work__text">Developer</div>
                        </div>
                    </div>

                    <div className="work__wrapper">
                        <a className="button" href="/Stefanos-Karakasis-CV.pdf" target="_blank">VIEW CV</a>
                    </div>
                </div>
            </AnimateDiv>

        </div>
    );
}

export default Work;
