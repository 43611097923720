import $ from "jquery";
$(document).ready(function () {
    let cursor = document.querySelector('.cursor');
    let cursorinner = document.querySelector('.cursor2');
    let a = document.querySelectorAll('a');

    document.addEventListener('mousemove', function (e) {
        const x = e.clientX;
        const y = e.clientY;
        if (cursor)
        cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
    });

    document.addEventListener('mousemove', function (e) {
        const x = e.clientX;
        const y = e.clientY;
        if (cursorinner) {
            cursorinner.style.left = x + 'px';
            cursorinner.style.top = y + 'px';
        }
    });

    document.addEventListener('mousedown', function () {
        if (cursor && cursorinner) {
            cursor.classList.add('click');
            cursorinner.classList.add('cursorinnerhover')
        }
    });

    document.addEventListener('mouseup', function () {
        if (!cursor)
            return
        cursor.classList.remove('click')
        cursorinner.classList.remove('cursorinnerhover')
    });

    a.forEach(item => {
        item.addEventListener('mouseover', () => {
            if (!cursor)
                return
            cursor.classList.add('hover');
        });
        item.addEventListener('mouseleave', () => {
            if (!cursor)
                return
            cursor.classList.remove('hover');
        });
    })
});