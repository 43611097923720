import $ from "jquery";

$(document).ready(function () {
    const selectors = {
        menu: '[data-cmp="menu"]',
        hamburger: '[data-cmp="hamburger"]',
        menuClose: '[data-cmp="menu-close"]',
    }

    $(selectors.hamburger).on('click', () => {
        $(selectors.menu).addClass('show');
    });

    $(selectors.menu).children().on('click', () => {
        $(selectors.menu).removeClass('show');
    });

    $(selectors.menuClose).on('click', () => {
        $(selectors.menu).removeClass('show');
    });
});