import "./Portfolio.scss"
import AnimateDiv from "../AnimateDiv/AnimateDiv";

function Portfolio() {
    return (
        <div id="work" className="portfolio">
            <AnimateDiv>
                <div className="d-flex flex-md-row flex-column">
                    <div className="title-sticky left">
                        <h4 className="hero__title">PORTFOLIO.</h4>
                        <div className="hero__subtitle">
                            Showcasing my apps. <strong>Click</strong> the boxes for more details
                        </div>
                    </div>

                    <div className="portfolio__wrapper pt-4">
                        <div className="portfolio__row">
                            <div className="portfolio__item maserati" data-cmp="trigger-hover" data-trigger="hover-3">
                                <div className="hover" data-hover-cmp="true" data-cmp="hover-3">
                                    <img className="portfolio__phone-image" src="/images/maserati/maserati_resized.webp" alt="maserati-site"/>
                                </div>
                            </div>

                            <div className="portfolio__details" data-cmp="card-details">
                                <div className="portfolio__details-wrapper">
                                    <div className="portfolio__details-front">
                                        <div className="portfolio__title">Maserati</div>
                                        <div className="portfolio__subtitle">-WEB APP</div>
                                    </div>

                                    <div className="portfolio__details-back">
                                        <div className="portfolio__text">Dev contribution on the new redesigned website of <a href="https://www.maserati.com/global/en">Maserati</a>.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="portfolio__row" data-hover="row">
                            <div className="portfolio__details" data-cmp="card-details">
                                <div className="portfolio__details-wrapper">
                                    <div className="portfolio__details-front">
                                        <div className="portfolio__title">Wallet Analyzer</div>
                                        <div className="portfolio__subtitle">-ANDROID APP</div>
                                    </div>

                                    <div className="portfolio__details-back">
                                        <div className="portfolio__text">An Android Application I developed with a colleague of mine, for our thesis in Technological Educational Institute of Central Macedonia.</div>
                                    </div>
                                </div>

                            </div>

                            <div className="portfolio__item wallet" data-cmp="trigger-hover" data-trigger="hover-1">
                                <div className="hover" data-hover-cmp="true" data-cmp="hover-1">
                                    <img className="portfolio__phone-image" src="/images/wallet/wallet-1.webp" alt="wallet analyzer img 1"/>
                                    <img className="portfolio__phone-image" src="/images/wallet/wallet-3.webp" alt="wallet analyzer img 2"/>
                                    <img className="portfolio__phone-image" src="/images/wallet/wallet-2.webp" alt="wallet analyzer img 3"/>
                                </div>

                            </div>
                        </div>

                        <div className="portfolio__row">

                            <div className="portfolio__item map-keeper" data-cmp="trigger-hover" data-trigger="hover-2">
                                <div className="hover" data-hover-cmp="true" data-cmp="hover-2">
                                    <img className="portfolio__phone-image" src="/images/map-keeper/map-keeper-1.jpg" alt="map-keeper-1"/>
                                    {/*    <img className"portfolio__phone-image" src="images/wallet/wallet-3.png" alt=""/>*/}
                                    {/*<img className"portfolio__phone-image" src="images/wallet/wallet-2.png" alt=""/> */}
                                </div>

                            </div>

                            <div className="portfolio__details" data-cmp="card-details">
                                <div className="portfolio__details-wrapper">
                                    <div className="portfolio__details-front">
                                        <div className="portfolio__title">Map Keeper</div>
                                        <div className="portfolio__subtitle">-WEB APP</div>
                                    </div>

                                    <div className="portfolio__details-back">
                                        <div className="portfolio__text">A side project I've been working on for Dungeons & Dragons tool to help organize maps.</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </AnimateDiv>
        </div>
    );
}

export default Portfolio;
