import React from "react";
import Greeting from "./components/Greeting/Greeting";
import Portfolio from "./components/Portfolio/Portfolio";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import "./scss/main.scss"
import "./components/Cursor/Cursor"
import "./components/Header/Menu"
import Video from "./components/Video/Video";
import Header from "./components/Header/Header";
import "bootstrap/scss/bootstrap.scss"
import "./components/Hover/hover"
import "./components/Hover/hover-animation"
import "./components/Scrollspy/Scrollspy"
import "./components/SendEmail/SendEmail"
import "./components/Typography"
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

function App() {

    const firebaseConfig = {
        apiKey: "AIzaSyAis7hBe0r8I0X0o_BoAZmDgt7ja5is8OI",
        authDomain: "stefanoskarakasis-5892c.firebaseapp.com",
        projectId: "stefanoskarakasis-5892c",
        storageBucket: "stefanoskarakasis-5892c.appspot.com",
        messagingSenderId: "899076690484",
        appId: "1:899076690484:web:01f780e2111e90daa9b061",
        measurementId: "G-XHMYWS6CXP"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return (
        <div className="App">

            <Video/>
            <Header/>

            <div className="container-fluid">
                <div data-cmp="components-parent" className="row position-relative components-parent">
                    <div id="about">
                        <Greeting/>
                        <Work/>
                    </div>


                    <Portfolio/>
                    <Contact/>
                </div>
            </div>

            <div className="cursor"></div>
            <div className="cursor2"></div>

        </div>
    );
}

export default App;
