import "./Contact.scss"
import AnimateDiv from "../AnimateDiv/AnimateDiv";

function Contact() {
    return (
        <div id="contact" className="contact-me">
            <AnimateDiv>
                <div className="contact-me__wrapper d-flex">
                    <div className="title-sticky left">
                        <h4 className="hero__title">CONTACT ME.</h4>
                        <div className="hero__subtitle contact-me__subtitle">
                            Contact me if you want a professional collaboration with me or you are searching for a
                            programmer.
                        </div>
                    </div>

                    <form className="contact-me__form">
                        <div className="contact-me__row">
                            <label className="contact-me__label" htmlFor="name">Name:</label>
                            <input data-cmp="sender-name" id="name" className="contact-me__input" type="text"/>
                        </div>

                        <div className="contact-me__row">
                            <label className="contact-me__label" htmlFor="email">Email:</label>
                            <input data-cmp="sender-email" id="email" className="contact-me__input" type="email"/>
                        </div>

                        <div className="contact-me__row">
                            <label className="contact-me__label" htmlFor="message">Message:</label>
                            <textarea data-cmp="sender-text" className="contact-me__input" name="message" id="message"
                                      cols="30" rows="8"></textarea>
                        </div>

                        <div className="contact-me__row">
                            <button data-cmp="send-email" type="button" className="contact-me__button mb-5">SEND</button>
                        </div>
                    </form>
                </div>
            </AnimateDiv>
        </div>
    );
}

export default Contact;
